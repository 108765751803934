<!--
 * @Author: gaojingran
 * @Date: 2021-04-06 13:49:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-06 14:56:39
 * @Description: 忘记密码
-->
<style lang="less" scoped>
.verify-item {
  display: flex;
  align-items: center;
  .item {
    flex: 1;
  }
}
</style>

<template>
  <a-modal
    :width="490"
    :title="$t('login.forget_password')"
    :cancelText="$t('cancel')"
    :okText="$t('submit')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form-model ref="form" :labelCol="labelCol" :wrapperCol="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item size="large" prop="password" :label="$t('login.new_password')">
        <a-input
          v-model="form.password"
          type="password"
          autocomplete="off"
          :placeholder="$t('login.new_password_holder')"
        />
      </a-form-model-item>

      <a-form-model-item size="large" prop="confirm" :label="$t('login.confirm_password')">
        <a-input
          v-model="form.confirm"
          type="password"
          autocomplete="off"
          :placeholder="$t('login.confirm_password_holder')"
        />
      </a-form-model-item>

      <a-form-model-item prop="email" :label="$t('login.email')">
        <div class="verify-item">
          <div class="item">
            <a-input v-model="form.email" autocomplete="off" :placeholder="$t('login.form_account')"> </a-input>
          </div>
          <a-button class="neo-btn-primary ml-2" @click="handleSendCode">{{ $t('login.send_verify_code') }}</a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item prop="captcha" :label="$t('login.verify')">
        <a-input v-model="form.captcha" :placeholder="$t('login.mail_verify_holder')" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { uuid } from '@/utils/utils'
import md5 from 'js-md5'
const { validate } = window.$g

export default {
  name: 'ForgetPassword',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkConfirm = (rule_, value, callback) => {
      const password = this.form.password
      if (value && password && value !== password) {
        callback(this.$t('login.check_confirm'))
      } else {
        callback()
      }
    }

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        email: '',
        password: '',
        confirm: '',
        captcha: '',
        uuid: '',
        systemType: 2,
        userType: 3,
      },
      rules: {
        email: [validate.required, validate.email],
        password: [validate.required],
        confirm: [validate.required, { validator: checkConfirm }],
        captcha: [validate.required],
      },
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        // 重置表单
        this.$refs.form.resetFields()
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false)
    },
    // 获取验证码
    handleSendCode() {
      this.$refs.form.validateField('email', async (err) => {
        if (!err) {
          try {
            this.form.uuid = uuid()
            await this.$http('auth_captchaByEmail', { ...this.form, loginAccount: this.form.email })
            this.$message.success(this.$t('login.send_verify_code_success'))
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
    handleOk() {
      if (!this.form.uuid) {
        return this.$message.error(this.$t('login.verify_code_not_send_yet'))
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.handleCancel()
            const password = md5(this.form.password)
            await this.$http('auth_resetPassword', { ...this.form, password })
            this.$message.success(this.$t('login.reset_password_success'))
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>
